import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Helmut from "../components/helmut"

const IndexPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Helmut title="Powerlifting Stream" />
      <h1>Powerlifting Streamed Live</h1>
      <p><a href="https://www.instagram.com/behindthedriver/">behindthedriver on Instagram</a></p>
      <p>
          <Link to="/blog/">Blog</Link>
      </p>
      <p><a href="mailto:chris@hamiltonracing.net">chris@hamiltonracing.net</a></p>
    </Layout>    
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
